import { useEffect, useState } from "react";

const getFaviconURl = (src: string) => `https://t3.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=${src}&size=256`;

const DEFAULT_ICON = "/authory_static_files/images/web.svg";

export const detectFaviconImageError = (originSrc: string, skipCheck = false) => {
    const [src, setSrc] = useState(skipCheck ? DEFAULT_ICON : getFaviconURl(originSrc));

    if (typeof window === "undefined") return src;

    useEffect(() => {
        if (skipCheck) return;
        const img = new Image();

        img.onload = (e) => {
            //@ts-ignore
            if (e?.target?.height == 16) setSrc(DEFAULT_ICON);
            else setSrc(getFaviconURl(originSrc));
        }

        img.src = getFaviconURl(originSrc);

    }, [originSrc]);

    return src;
}