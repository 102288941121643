import { ArticlePreviewResponse, ContentAnalyticsResult, ContentItemPreview, ContentResponse } from "authory-api-types/dist/types";
import { detectFaviconImageError } from "../../hooks/detectFaviconImageError";
import { theme } from "../../theme/theme";
import { V3IconRedirect } from "../Icons"
import { getContentData } from "../../utils/article-text-utils"
import { LearnMoreCTA } from "../ReusableStyledComponents/LearnMoreCTA";
import { V3Tooltip } from "../Tooltip";
import { PLIMetadata, PLIFavicon, PLISourceMetaDateWrapper, PLIMetadataText, PLISourceMetaWrapper, PLISourceMetaIconWrapper, PLISmallAvatar, PLISourceOriginalWrapper, PLIMetadataOriginalLink, RedirectIconLink } from "./V3ProfileListItem.styles"
import { renderArticleDate } from "../../utils/articleDateParser";
import { EmbedTheme } from "../../utils/embeds";
import { ContentType, SourceType } from "authory-api-types/dist/enums";
import { UrlParser } from "authory-api-types/dist/types/source/parsing";

interface ItemMetadata {
    item: ArticlePreviewResponse | ContentAnalyticsResult | ContentItemPreview | ContentResponse | { originalUrl: string | null, sourceName?: string, date: string | null };
    showRedirect?: boolean,
    isB2B?: boolean,
    hideFavicon?: boolean
    customDateFormat?: string,
    isPrivateView?: boolean,
    hideUserAvatar?: boolean,
    viewOriginal?: boolean,
    embedTheme?: EmbedTheme,
    isEmbed?: boolean,
}

export const ItemMetadata = ({ item, showRedirect = true, isB2B, hideFavicon = false, customDateFormat = 'MMMM dd, yyyy', isPrivateView = false, hideUserAvatar = false, viewOriginal = false, isEmbed = false, embedTheme = EmbedTheme.light }: ItemMetadata) => {

    let isOffline = false;
    let domain = "";

    try {
        try {
            domain = UrlParser.extractDomain(item.originalUrl) || "";
        } catch {
            domain = "";
        }
    } catch { }

    if (
        (!!domain.length && domain.includes("authory.com"))
        ||
        ('sourceType' in item && item.sourceType === SourceType.Offline)
        ||
        (typeof item.sourceName !== "string" && 'sourceType' in item && item.sourceType === SourceType.Offline)
    ) {
        isOffline = true;
    }

    const imgSrc = detectFaviconImageError(item.originalUrl || "", isOffline);

    const RedirectTooltip = () => <V3Tooltip
        tooltipMaxWidth={isPrivateView ? 370 : 320}
        referenceContent={<V3IconRedirect />}
        tooltipContent={isPrivateView
            ? <>For visitors, this content item will link out to the original content instead of the content copy on Authory. <LearnMoreCTA href="https://authory.com/help/en/articles/6359541-how-to-change-what-happens-if-visitors-click-on-your-content" /></>
            : "This preview links out to the original content."
        }
    />

    const sourceLabel = isB2B && 'owner' in item && item.owner ? `${item.owner.firstName} ${item.owner.lastName}` : item.sourceName;

    return <PLIMetadata $isEmbed={isEmbed} $embedTheme={embedTheme}>
        {
            !!item.originalUrl && !isB2B && !hideFavicon && <PLIFavicon $faviconUrl={imgSrc || ""} suppressHydrationWarning />
        }
        {
            isB2B && !hideUserAvatar && 'owner' in item && item.owner && item.owner.avatarUrl.length && <PLISmallAvatar
                profileSrc={item.owner.avatarUrl[0].url}
            />
        }
        <PLISourceMetaDateWrapper>
            <PLIMetadataText suppressHydrationWarning>{renderArticleDate(item.date, customDateFormat)}</PLIMetadataText>
        </PLISourceMetaDateWrapper>
        <PLISourceMetaWrapper>
            <PLIMetadataText>{getContentData('type' in item ? item.type : ContentType.Article).label} {!!sourceLabel ? <> {isB2B ? "by" : "at"} <strong>{sourceLabel}</strong></> : <></>}</PLIMetadataText>
        </PLISourceMetaWrapper>
        {
            viewOriginal && item.originalUrl && <PLIMetadataOriginalLink
                as="a"
                target="_blank"
                href={item.originalUrl}
            >
                <PLISourceOriginalWrapper>
                    <div>
                        <V3IconRedirect stroke={theme.colors.blackPearl3} />
                    </div>
                    <div>View original</div>
                </PLISourceOriginalWrapper>
            </PLIMetadataOriginalLink>
        }
        {
            showRedirect && !isPrivateView && <PLISourceMetaIconWrapper>
                <RedirectIconLink
                    href={item.originalUrl || "#"}
                    target="_blank"
                >
                    <RedirectTooltip />
                </RedirectIconLink>
            </PLISourceMetaIconWrapper>
        }
    </PLIMetadata>
}