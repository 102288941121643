import { ProfileView, Visibility } from "authory-api-types/dist/enums";
import { ArticlePreviewResponse } from "authory-api-types/dist/types";
import { useEffect, useState } from "react";
import { useWindowSize } from "../../hooks/useWindowSize";
import { theme } from "../../theme/theme";
import { VerticalSpacing } from "../VerticalSpacing";
import { ItemMetadata } from "./ItemMetadata";
import { ItemImageWrapper, ItemTextWrapper, PlIListThumb, PLIThumbnail, PLIThumbnailPad, PLIWrapper, V3FeedDescription, V3FeedTitle } from "./V3ProfileListItem.styles";
import { getDescNumLines, getTitleNumLines } from "./V3ProfileListItem.utils";
import { V3ProfileListItemLink } from "./V3ProfileListItemLink";

export interface V3ProfileListItemProps {
    item: ArticlePreviewResponse,
    viewType: ProfileView,
    isPinned?: boolean,
    href: string,
    isB2B?: boolean,
    target?: string
    isPrivateView?: boolean,
    isSignupPreview?: boolean,
}

export const V3ProfileListItem = ({
    item,
    viewType = ProfileView.TwoColumnThumbnail,
    isPinned,
    href,
    isB2B,
    target,
    isPrivateView,
    isSignupPreview = false,
}: V3ProfileListItemProps) => {
    const [dateFormat, setDateFormat] = useState<string | undefined>(undefined);

    // Hooks
    const size = useWindowSize();

    // Views
    const isTwoColumn = viewType === ProfileView.TwoColumnThumbnail;
    const isOneColumn = viewType === ProfileView.OneColumnThumbnail;
    const isText = viewType === ProfileView.Text;
    const isList = viewType === ProfileView.List;

    const isTwoOrOneColumn = isTwoColumn || isOneColumn;

    const isTwoColumnPinned = isTwoColumn && isPinned;

    const hasImage = !!item.previewImage && !!item.thumbnailImage;
    const hasDescription = !!item.description || !!item.ogDescription;
    const hasTitle = !!item.title || !!item.ogTitle;

    const titleLines = getTitleNumLines(viewType, hasImage, hasDescription);
    const descLines = getDescNumLines(viewType, hasImage, hasTitle);

    const isTwoColumnWithImageOrTitle = isTwoColumn && (!hasImage || !hasTitle);
    const showDesc = (hasDescription || isSignupPreview) && (!isTwoColumn || isTwoColumnWithImageOrTitle);

    useEffect(() => {
        if (!!size && !!size.width && size.width < theme.v3.layout.sidebarLayout.mobile) {
            setDateFormat("MMM dd yy");
        } else {
            setDateFormat(undefined);
        }
    }, [size.width]);

    return <PLIWrapper $dash={isText} $isPinned={isPinned} $viewType={viewType}>
        {
            !isList && hasImage && <ItemImageWrapper $viewType={viewType} $isPinned={isPinned}>
                <V3ProfileListItemLink href={href} target={target} >
                    {
                        isTwoOrOneColumn && <PLIThumbnailPad $isTwoColumnPinned={isTwoColumnPinned}>
                            <PLIThumbnail
                                $thumbnailImage={isTwoColumn ? item.thumbnailImage : item.previewImage}
                                $viewType={viewType}
                                $isPinned={isPinned}
                            />
                        </PLIThumbnailPad>
                    }
                </V3ProfileListItemLink>
            </ItemImageWrapper>
        }
        <ItemTextWrapper $isPinned={isPinned} $viewType={viewType}>
            <div>
                <V3ProfileListItemLink href={href} target={target} >
                    {
                        hasTitle && <VerticalSpacing bottom={theme.v3.spacings.spacing3}>
                            <V3FeedTitle as="h2" $numLines={titleLines}>{item.ogTitle || item.title}</V3FeedTitle>
                        </VerticalSpacing>
                    }
                    {
                        showDesc && <VerticalSpacing bottom={theme.v3.spacings.spacing3}>
                            <V3FeedDescription $numLines={descLines} >{item.ogDescription || item.description}</V3FeedDescription>
                        </VerticalSpacing>
                    }
                </V3ProfileListItemLink>
                <ItemMetadata
                    item={item}
                    customDateFormat={dateFormat}
                    isPrivateView={isPrivateView}
                    isB2B={isB2B}
                    showRedirect={item.effectiveVisibility === Visibility.Preview && !isSignupPreview}
                />
            </div>
        </ItemTextWrapper>
        {
            isList && hasImage && <PlIListThumb>
                <V3ProfileListItemLink href={href} target={target} >
                    <PLIThumbnail
                        $thumbnailImage={item.thumbnailImage}
                        $viewType={viewType}
                        $isPinned={isPinned}
                    />
                </V3ProfileListItemLink>
            </PlIListThumb>
        }
    </PLIWrapper >
}