import Link from "next/link"
import { UnstyledLink } from "../ReusableStyledComponents/UnstyledLink"

export const V3ProfileListItemLink: React.FunctionComponent<React.PropsWithChildren<{ href: string, target: string | undefined }>> = ({ href, target, children }) => {

    if (target === "_blank") {
        return <UnstyledLink href={href} target={target} rel="nofollow">{children}</UnstyledLink>
    }

    return <Link href={href} passHref legacyBehavior>
        <UnstyledLink target={target}>
            {children}
        </UnstyledLink>
    </Link>
}