import { ProfileView } from "authory-api-types/dist/enums";
import { theme } from "../../theme/theme";
import { VerticalSpacing } from "../VerticalSpacing";
import { FakeMetadata, ItemImageWrapper, ItemMockAddBtnPos, ItemTextWrapper, PlIListThumb, PLIThumbnail, PLIThumbnailPad, PLIWrapper, SecondaryTextMockWrapper, V3FeedDescription, V3FeedTitle } from "./V3ProfileListItem.styles";
import { getDescNumLines, getTitleNumLines } from "./V3ProfileListItem.utils";
import { useAddElementSkeletonTracker } from "../../context/addElementSkeletonContext";
import { useInView } from "react-intersection-observer";
import { V3IconPlus } from "../Icons";
import { V3ButtonVariant } from "../Button/V3Button.types";
import { V3CaptionGrey35 } from "../ReusableStyledComponents/V3CaptionGrey35";
import { V3Button } from "../Button";

interface V3ProfileListItemMockProps {
    viewType: ProfileView,
    trackOnScreen?: boolean,
    isProfileMock?: boolean
}

export const V3ProfileListItemMock = ({
    viewType = ProfileView.TwoColumnThumbnail,
    trackOnScreen = true,
    isProfileMock = true,
}: V3ProfileListItemMockProps) => {

    const { onScreen, setOnScreen, setAddContentOpen } = useAddElementSkeletonTracker();

    const { ref, inView } = useInView({
        threshold: 0,
        rootMargin: "0px 0px 0px",
        initialInView: false
    });

    if (inView && !onScreen && trackOnScreen) setOnScreen(true);
    if (!inView && onScreen && trackOnScreen) setOnScreen(false);

    // Views
    const isTwoColumn = viewType === ProfileView.TwoColumnThumbnail;
    const isOneColumn = viewType === ProfileView.OneColumnThumbnail;
    const isText = viewType === ProfileView.Text;
    const isList = viewType === ProfileView.List;

    const isTwoOrOneColumn = isTwoColumn || isOneColumn;

    const titleLines = getTitleNumLines(viewType, true, true);
    const descLines = getDescNumLines(viewType, true, true);

    return <PLIWrapper $dash={isText} $isPinned={false} $viewType={viewType} $isMock={isProfileMock} ref={ref} onClick={() => setAddContentOpen(true)}>
        {
            !isList && <ItemImageWrapper $viewType={viewType} $isPinned={false}>
                {
                    isTwoOrOneColumn && <PLIThumbnailPad $isProfileMock={isProfileMock}>
                        <PLIThumbnail
                            $thumbnailImage={null}
                            $viewType={viewType}
                        />
                    </PLIThumbnailPad>
                }
            </ItemImageWrapper>
        }
        <ItemTextWrapper $viewType={viewType}>
            <div>
                {
                    (!isProfileMock || isList || isText) && <>
                        <VerticalSpacing bottom={!isProfileMock ? theme.v3.spacings.spacing3 : undefined}>
                            <V3FeedTitle $isMock as="h2" $numLines={isProfileMock ? 5 : titleLines}>{Array(200).join("a ")}</V3FeedTitle>
                        </VerticalSpacing>
                    </>
                }
                {
                    !isProfileMock && !isTwoColumn && <>
                        <VerticalSpacing bottom={theme.v3.spacings.spacing3}>
                            <V3FeedDescription $isMock $numLines={descLines}>{Array(200).join("a ")}</V3FeedDescription>
                        </VerticalSpacing>
                    </>
                }
                {!isProfileMock && <FakeMetadata $isMock />}
            </div>
        </ItemTextWrapper>
        {
            isList && !isProfileMock && <PlIListThumb>
                <PLIThumbnail
                    $thumbnailImage={null}
                    $viewType={viewType}
                />
            </PlIListThumb>
        }
        {isProfileMock && <ItemMockAddBtnPos>
            <V3Button
                data-intercom-target="UniversalAddContent"
                icon={<V3IconPlus />}
                text={"Add work samples to this collection"}
                autoWidth
                onClick={() => setAddContentOpen(true)}
                variant={V3ButtonVariant.large}
                minWidth={160}
            />
            <VerticalSpacing top={14}>
                <SecondaryTextMockWrapper>
                    <V3CaptionGrey35>Import new content or select previously imported pieces from your Content Archive.</V3CaptionGrey35>
                </SecondaryTextMockWrapper>
            </VerticalSpacing>
        </ItemMockAddBtnPos>}
    </PLIWrapper >
}