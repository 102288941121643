import { ProfileView } from "authory-api-types/dist/enums";

export const getTitleNumLines = (viewType: ProfileView, _hasImage: boolean, hasDescription: boolean) => {
    if (viewType === ProfileView.TwoColumnThumbnail) {
        return 2;
    }

    if (viewType === ProfileView.OneColumnThumbnail) {
        return 2;
    }

    if (viewType === ProfileView.List) {
        if (!hasDescription) return 3;
    }

    return 2;
}

export const getDescNumLines = (viewType: ProfileView, hasImage: boolean, hasTitle: boolean) => {
    if (viewType === ProfileView.TwoColumnThumbnail) {
        if (!hasImage && !hasTitle) return 6;
        if (!hasImage) return 5;
        if (!hasTitle) return 3;
    }

    if (viewType === ProfileView.OneColumnThumbnail) {
        if (!hasImage && !hasTitle) return 6;
        return 3;
    }

    if (viewType === ProfileView.List) {
        if (!hasTitle || !hasImage) return 3;
    }

    if (viewType === ProfileView.Text) {
        if (!hasTitle) return 3;
    }

    return 2;
}