import { ProfileView } from "authory-api-types/dist/enums";
import styled, { css } from "styled-components";
import { media } from "../../theme/theme";
import { EmbedTheme } from "../../utils/embeds";
import { V3Body, V3HeadlineH3, V3Paragraph } from "../Typography";
import { ClampMixin, ClampMixinType } from "../ReusableStyledComponents/ClampMixin";

export const PLIFavicon = styled.div<{ $faviconUrl?: string }>`
    background-image: url(${({ $faviconUrl }) => $faviconUrl ? $faviconUrl : null});
    background-size: contain;
    background-repeat: no-repeat;
    height: 16px;
    width: 16px;
    margin-right: 10px;
    flex-shrink: 0;
`;

export const PLIMetadataText = styled(V3Body)`
    color: ${({ theme }) => theme.v3.colors.grey48};
    text-overflow: ellipsis;
    white-space: nowrap;
    width: calc(100%);
    overflow: hidden;
`;

export const PLIMetadata = styled.div<{ $isEmbed: boolean, $embedTheme: EmbedTheme }>`
    display: flex;
    align-items: center;
    max-width: calc(100%);

    ${PLIMetadataText} {
        font-size: ${({ $isEmbed }) => $isEmbed ? "12px" : undefined};
        line-height: ${({ $isEmbed }) => $isEmbed ? "16px" : undefined};
        color: ${({ $embedTheme, theme, $isEmbed }) => !$isEmbed ? undefined : $embedTheme === EmbedTheme.dark ? theme.v3.colors.grey70 : theme.v3.colors.grey48};
    }
`;

export const ItemTextWrapper = styled.div<{ $viewType: ProfileView, $isPinned?: boolean }>`
    padding: ${({ $viewType, $isPinned }) => {
        if ($viewType === ProfileView.OneColumnThumbnail && $isPinned) return "0 18px 18px";
        if ($viewType === ProfileView.TwoColumnThumbnail && $isPinned) return "32px 12px 12px 48px";
        return null;
    }};
    width: ${({ $viewType, $isPinned }) => {
        if ($viewType === ProfileView.TwoColumnThumbnail && $isPinned) return "50%";
        return null;
    }};
`;

export const ItemImageWrapper = styled.div<{ $viewType: ProfileView, $isPinned?: boolean }>`
    width: ${({ $viewType, $isPinned }) => {
        if ($viewType === ProfileView.TwoColumnThumbnail && $isPinned) return "50%";
        return null;
    }};

    a {
        display: block;
        width: 100%;
    }
`;

export const PLIThumbnailPad = styled.div<{ $isTwoColumnPinned?: boolean, $isProfileMock?: boolean }>`
    padding-bottom: ${({ $isTwoColumnPinned, $isProfileMock }) => !$isProfileMock && !$isTwoColumnPinned ? "18px" : undefined};
`;

export const PLIThumbnail = styled.div<{ $thumbnailImage: string | null, $viewType: ProfileView, $isPinned?: boolean }>`
    background: ${({ theme }) => `${theme.v3.colors.grey96}`};
    background-image: url(${({ $thumbnailImage }) => $thumbnailImage ? $thumbnailImage : null});
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    position: relative;
    border-radius: 6px;
    overflow: hidden;
    height: 0;
    transition: background-color 0.3s ease, border-color 0.3s ease;

    padding-bottom: ${({ $viewType, $isPinned }) => {
        if ($viewType === ProfileView.TwoColumnThumbnail && !$isPinned) return "44.73%";
        if ($viewType === ProfileView.TwoColumnThumbnail && $isPinned) return "59.75%";
        if ($viewType === ProfileView.OneColumnThumbnail && !$isPinned) return "32.12%";
        if ($viewType === ProfileView.OneColumnThumbnail && $isPinned) return "31.45%";
        if ($viewType === ProfileView.List && $isPinned) return "96.61%";
        if ($viewType === ProfileView.List && !$isPinned) return "75%";
        return null;
    }};
`

export const PLISourceMetaWrapper = styled.div`
    margin-right: 12px;
    white-space: nowrap;
    max-width: calc(100%);
    text-overflow: ellipsis;
    overflow: hidden;
`;

export const PLISourceMetaIconWrapper = styled.div`
    display: inline-flex;
`;

export const PLISourceMetaDateWrapper = styled.div`
    padding-right: 24px;
    flex-shrink: 0;
    position: relative;

    &:after {
        content:"•";
        width: 6px;
        height: 6px;
        color:  ${({ theme }) => `${theme.colors.blackPearl3}`};
        position: absolute;
        top: 4px;
        right: 9px;
        transform: scale(0.6);
    }
`;

export const PLISmallAvatar = styled.div<{ profileSrc?: string }>`
    height: 24px;
    width: 24px;
    margin-right: 10px;
    background-color: grey;
    background-image: ${({ profileSrc }) => profileSrc ? `url(${profileSrc})` : undefined};
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 50%;
    flex-shrink: 0;
`;

export const PlIListThumb = styled.div`
    max-width: 228px;
    padding-left: 20px;
    flex-shrink: 0;
`;

type OverlayMixinType = { $isMock?: boolean };

const overlayMixin = css<OverlayMixinType>`
    position: relative;
    color: ${({ $isMock }) => $isMock ? "white" : undefined};
    border-radius: ${({ $isMock }) => $isMock ? "6px" : undefined};
    overflow: ${({ $isMock }) => $isMock ? "hidden" : undefined};

    &:after {
        transition: background-color 0.3s ease;
        position: absolute;
        top: 0;
        right: 0;
        content: "";
        width: 100%;
        height: 100%;
        display: ${({ $isMock }) => $isMock ? "block" : "none"};
        background: ${({ theme }) => `${theme.v3.colors.grey96}`};
    }
`;

export const V3FeedTitle = styled(V3HeadlineH3) <ClampMixinType & OverlayMixinType>`
    ${ClampMixin};
    ${overlayMixin}
`;

export const V3FeedDescription = styled(V3Paragraph) <ClampMixinType & OverlayMixinType>`
    ${ClampMixin};
    ${overlayMixin};
    word-break: break-word;
`;

export const FakeMetadata = styled.div<OverlayMixinType>`
    height: 19px;
    width: 100%;
    max-width: 250px;
    ${overlayMixin}
`;

const SMALL_REDIRECT_BR = 480;

export const PLISourceOriginalWrapper = styled.div`
    flex-shrink: 0;
    position: relative;
    display: flex;
    align-items: center;

    > div {
        &:first-child {
            display: inline-flex;
            padding-left: 16px;
            margin-right: 6px;
        }

        &:nth-child(2) {
            display: none;

            ${media.custom(SMALL_REDIRECT_BR)} {
                display: block;
            };
        }
    }

    &:before {
        content:"•";
        width: 6px;
        height: 6px;
        color:  ${({ theme }) => `${theme.colors.blackPearl3}`};
        position: absolute;
        top: 3px;
        left: 0px;
        transform: scale(0.6);
    }
`;

export const PLIMetadataOriginalLink = styled(PLIMetadataText)`
    text-decoration: none;
    display: block;
    width: auto;
    min-width: 32px;

    ${media.custom(SMALL_REDIRECT_BR)} {
        min-width: 115px;
    };

    &:hover {
        text-decoration: underline;
    }
`;

export const RedirectIconLink = styled.a``;

export const PLIWrapper = styled.div<{ $dash?: boolean, $isPinned?: boolean, $viewType: ProfileView, $isMock?: boolean }>`
    display: ${({ $viewType, $isPinned }) => {
        if ($viewType === ProfileView.List || ($isPinned && $viewType === ProfileView.TwoColumnThumbnail)) return "flex";
        return null;
    }};
    position: relative;
    align-items: center;
    padding: ${({ $isMock }) => $isMock ? "12px" : undefined};
    cursor: ${({ $isMock }) => $isMock ? "pointer" : undefined};
    border:  ${({ $isMock }) => $isMock ? "1.5px dashed rgba(110, 118, 135, 0.3)" : undefined};
    border-radius: 6px;

    &:hover {
        border: ${({ $isMock }) => $isMock ? "1.5px dashed rgba(110, 118, 135, 0.5)" : undefined};

        ${PLIThumbnail} {
            background: ${({ $isMock, theme }) => $isMock ? `${theme.v3.colors.grey88}` : undefined};
        }

        ${V3FeedTitle},
        ${V3FeedDescription},
        ${FakeMetadata} {
            &:after {
                background: ${({ $isMock, theme }) => $isMock ? `${theme.v3.colors.grey88}` : undefined};
            }
        }
    }

    > div {
        width: 100%;
       
        &:first-child {
            padding: ${({ $viewType, $isPinned }) => {
        if ($viewType === ProfileView.List && $isPinned) return "18px";
        return null;
    }};

            display: ${({ $viewType, $isPinned }) => {
        if ($viewType === ProfileView.TwoColumnThumbnail && $isPinned) return "flex";
        return null;
    }};

            align-items: ${({ $viewType, $isPinned }) => {
        if ($viewType === ProfileView.TwoColumnThumbnail && $isPinned) return "center";
        return null;
    }};
        }
    }
`;

export const ItemMockAddBtnPos = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const SecondaryTextMockWrapper = styled.div`
    max-width: 340px;
    padding: 0 18px;
    text-align: center;
`;